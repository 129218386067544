html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 48px;
  color: #444;
  overflow-x: hidden;
}

code {
  color: #58585a;
}

a {
  text-decoration: none !important;
}

.lr-tooltip {
  color: white !important;
  background-color: #444 !important;
}

.lr-tooltip.place-top:after {
  border-top-color: #444 !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}
.lr-tooltip.place-bottom:after {
  border-bottom-color: #444 !important;
  border-bottom-style: solid !important;
  border-bottom-width: 6px !important;
}
.lr-tooltip.place-left:after {
  border-left-color: #444 !important;
  border-left-style: solid !important;
  border-left-width: 6px !important;
}
.lr-tooltip.place-right:after {
  border-right-color: #444 !important;
  border-right-style: solid !important;
  border-right-width: 6px !important;
}

.cesium-viewer,
.cesium-viewer-cesiumWidgetContainer,
.cesium-widget {
  position: absolute !important;
  height: 100%;
  width: 100%;
}

.cesium-viewer-bottom {
  display: none;
}

.fa-fast-spin {
  animation-duration: 1.2s;
}

.clickable:hover {
  cursor: pointer;
}

.flex-row-hover:hover {
  background-color: #eee;
}

*:focus {
  outline: none !important;
  /* box-shadow: none !important; */
}

/*
input:focus {
    outline: none;
    border-color: #f5751c;
    box-shadow: inset 0 0 2px #f5751c;
}
*/

.cesium-widget > canvas {
  width: 100%;
  height: 100%;
  outline: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.rc-slider-handle:focus {
  outline: none;
  border-color: #ddd;
  box-shadow: none !important;
}

select:focus {
  border-color: #f5751c !important;
}

a {
  color: #f5751c !important;
}

.rc-slider-handle:active {
  outline: none;
  border-color: #ddd;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 3px rgba(255, 255, 255, 0.6) !important;
}

h1 {
  font-size: 2rem;
  color: #666;
}
h2 {
  font-size: 1.75rem;
  color: #666;
}
h3 {
  font-size: 1.5rem;
  color: #666;
}
h4 {
  font-size: 1.25rem;
  color: #666;
}
h5 {
  font-size: 1rem;
  color: #666;
}
h6 {
  font-size: 0.8rem;
  color: #666;
}

/* Color picker */
.hue-horizontal {
  margin-left: 15px !important;
  margin-right: 15px !important;
  border-radius: 4px !important;
  height: 14px !important;
  border: 1px solid rgb(221, 221, 221) !important;
}

.ol-zoom {
  background-color: #dedede !important;
}

.ol-zoom-in,
.ol-zoom-out {
  background-color: white !important;
  color: #f5751c !important;
}

.hue-horizontal > div > div {
  width: 32px !important;
  height: 24px !important;
  border-radius: 4px !important;
  transform: translate(-16px, -6px) !important;
  box-shadow: none !important;
  border: 1px solid rgb(221, 221, 221) !important;
  background-color: white !important;
}
